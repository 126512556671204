<template>
    <div class="homeWrap">
        <Header :isFixed="true"></Header>
        <video class="loginView" src="../../assets/video/logo-bg.mp4" autoplay loop muted>
        </video>
        <div class="bannerText" :class="{en:this.$i18n.locale==`EN`}">
            <div class="content">
                <h3 v-html="$t('oa.homeBannertTitle1')"></h3>
                <p v-html="$t('oa.homeBannertTitle2')"></p>
            </div>
        </div>
        <div class="homeContent content">
            <div class="newsWrap">
                <div class="title">{{$t('oa.News')}}</div>
                <div class="newsList">
                    <div class="leftView">
                        <div class="newsItem frist" v-if="newsFristItem" @click="toNews(newsFristItem)">
                            <div class="imgbox">
                                <img :src="newsFristItem.urlPath">
                            </div>
                            <div class="textInfor">
                                <div class="date">{{newsFristItem.newDate}}</div>
                                <div class="newstitle">{{newsFristItem.subject}}</div>
                                <div class="summary">{{newsFristItem.summary}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="rightView">
                        <div class="newsItem" v-for="(element,index) in newsList" @click="toNews(element)" :key="index">
                            <div class="imgbox">
                                <img :src="element.urlPath">
                            </div>
                            <div class="textInfor">
                                <div class="date">{{element.newDate}}</div>
                                <div class="newstitle">{{element.subject}}</div>
                            </div>
                        </div>
                        <!-- <div class=""></div> -->
                    </div>
                </div>
            </div>
            <div class="displayPos">
                <div class="title">{{$t('oa.dailyPosition')}}</div>
                <div class="displayList">
                    <div class="displayItem" v-for="(element,index) in dispalyPosList" @click.prevent="toDispalyPos(element)" :key="index">
                        <div class="left"><img src="../../assets/images/pdf.png"></div>
                        <div class="center">
                            <div class="date">{{element.date}}</div>
                            <div class="dptitle">{{element.fileName}}</div>
                        </div>
                        <!-- <div class="right" @click.stop="downPDFDocument(element)">
                            <img src="../../assets/images/download_hold.png">
                        </div> -->
                    </div>
                    <!-- <div class="more" v-if="dispalyPosList" @click="toDispalyPos"><img src="../../assets/images/black.png"></div> -->
                </div>
            </div>
            <div class="contactUsWrap">
                <div class="contactUsBox">
                    <div class="leftView">
                        <h3>{{$t('oa.coutcontactus')}}</h3>
                        <p v-html="$t('oa.contcontactusTips')"></p>
                    </div>
                    <div class="rightView">
                        <el-form ref="contactUs" :model="contactUs" :rules="contactUsRules" class="contactUs">
                            <el-form-item prop="name">
                                <el-input v-model="contactUs.name" :placeholder="$t('oa.yourName')"></el-input>
                            </el-form-item>
                            <el-form-item prop="email">
                                <el-input v-model="contactUs.email" :placeholder="$t('oa.emailCus')"></el-input>
                            </el-form-item>
                            <el-form-item prop="iphone">
                                <el-input v-model="contactUs.iphone" :placeholder="$t('oa.phone')"></el-input>
                            </el-form-item>
                            <el-form-item prop="subject">
                                <el-input v-model="contactUs.subject" :placeholder="$t('oa.subject')"></el-input>
                            </el-form-item>
                            <el-form-item prop="content">
                                <el-input v-model="contactUs.content" type="textarea" :placeholder="$t('oa.message')"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="submitButton submitBtn" @click="contactUsForm('contactUs')" round :disabled="loadingSubmit" :loading="loadingSubmit">{{$t('oa.submit')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import url from "@/api/url.js";
import Header from "@/components/header.vue";
export default {
    components: {
        Header,
    },
    data() {
        return {
            loadingSubmit: false,
            newsFristItem: {},
            contactUs: {
                name: "",
                email: "",
                iphone: "",
                subject: "",
                content: "",
            },
            contactUsRules: {
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                ],
                iphone: [
                    { required: true, message: "请输入手机", trigger: "blur" },
                ],
                subject: [
                    { required: true, message: "请输入主题", trigger: "blur" },
                ],
                content: [
                    { required: true, message: "请输入信息", trigger: "blur" },
                ],
            },
            newsList: [],
            dispalyPosList: [],
            swiperOptions: {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                loop: false,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                slidesPerView: 4,
                centerInsufficientSlides: true,
            },
        };
    },
    created() {
        this.getdispalyPosList();
        this.getNewsList();
        this.initLang();
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
    },
    methods: {
        initLang() {
            this.contactUsRules.name[0].message = this.$t("oa.enterYourName");
            this.contactUsRules.email[0].message = this.$t("oa.enteremail");
            this.contactUsRules.iphone[0].message = this.$t("oa.enterPhone");
            this.contactUsRules.subject[0].message =
                this.$t("oa.eneterSubject");
            this.contactUsRules.content[0].message =
                this.$t("oa.eneterMessage");
        },
        contactUsForm(formName) {
            if (this.loadingSubmit) return;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitContactUsForm(formName);
                } else {
                    return false;
                }
            });
        },
        submitContactUsForm(formName) {
            this.loadingSubmit = true;
            this.$axios
                .post(url.contactUs, {
                    ...this.contactUs,
                })
                .then((res) => {
                    this.$message({
                        type: "success",
                        message: this.$t("oa.emailSuccess"),
                    });
                })
                .finally(() => {
                    this.loadingSubmit = false;
                    this.resetForm(formName);
                });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        downPDFDocument(e) {
            let token = localStorage.getItem("AUTtoken");
            this.fileUrl = e.fileUrl;
            window.open(`/oa/download?fileUrl=${this.fileUrl}&token=${token}`);
        },
        getNewsList() {
            this.$axios
                .post(url.newsList, {
                    page: 1,
                    limit: 5,
                })
                .then((res) => {
                    this.newsFristItem = res.page.list.splice(0, 1)[0];
                    this.newsList = res.page.list;
                });
        },
        getdispalyPosList() {
            this.$axios
                .get(url.fundfileinfor, {
                    params: {
                        page: 1,
                        limit: 3,
                    },
                })
                .then((res) => {
                    this.dispalyPosList = res.page.list;
                });
        },
        toDispalyPos(item) {
            this.$router.push({
                path: "/dailyPosition/summary",
            });
        },
        toNews(item) {
            this.$router.push({
                path: "/news",
                query: { id: item.uuid },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.homeWrap {
    .loginView {
        position: relative;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: 9;
        display: block;
        background: url("../../assets/images/contentBG.jpg") center center /
            cover;
    }
    .bannerText {
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 9;
        display: flex;
        align-items: center;
        margin: 0 auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-family: "微软雅黑" !important;
        .content {
            min-height: 200px;
            margin-left: 0;
            text-align: center;
            h3 {
                font-size: 60px;
                font-weight: bold;
                color: #fff;
                margin-bottom: 30px;
            }
            p {
                color: #fff;
                line-height: 40px;
                font-size: 28px;
            }
        }
    }
    .en {
        font-family: FrutigerLTStd;
    }
    .homeContent {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        background-color: #fff;

        .title {
            font-size: 48px;
            padding-bottom: 70px;
            color: #ce995f;
            text-align: center;
            margin: 0px auto;
            position: relative;
        }
        .newsWrap {
            padding: 80px 0;
            margin: 0 auto;
            position: relative;
            .newsList {
                width: 1320px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .leftView {
                    .frist {
                        width: 650px;
                        height: 650px;
                        display: block;
                        padding: 20px;
                        margin-bottom: 0;
                        .imgbox {
                            width: 100%;
                            height: 360px;
                        }
                        .textInfor {
                            .newstitle {
                                color: #000000;
                                font-family: "FrutigerLTStd LT Std";
                                font-size: 24px;
                                letter-spacing: 0;
                                line-height: 30px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                            }
                            .date {
                                font-size: 18px;
                                padding: 10px 0;
                            }
                            .summary {
                                color: #000000;
                                font-size: 14px;
                                letter-spacing: 0;
                                padding-top: 20px;
                                line-height: 24px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                            }
                        }
                    }
                }
                .newsItem {
                    padding: 15px 20px;
                    width: 610px;
                    height: 150px;
                    margin-bottom: 17px;
                    box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
                    border-radius: 4px;
                    overflow: hidden;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    display: flex;

                    .textInfor {
                        flex: 1;
                        padding: 0 10px;
                        .date {
                            color: #ce995f;
                            font-weight: bold;
                            font-size: 13px;
                            padding: 5px 0;
                        }
                        .newstitle {
                            font-size: 15px;
                            font-weight: bold;
                            line-height: 24px;
                            color: #000000;
                            min-height: 62px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                        }
                    }
                    .imgbox {
                        width: 160px;
                        height: 120px;
                        margin: 0px auto;
                        border-radius: 6px;
                        overflow: hidden;
                        position: relative;
                        img {
                            display: block;
                            height: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%) scale(1);
                            transition: 0.3s ease-in-out;
                        }
                    }
                }
                .newsItem:hover {
                    .imgbox {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }
            }
        }
        .displayPos {
            width: 100%;
            // height: 755px;
            // background: url("../../assets/images/pattern.png");
            // background-color: #efeff0;
            padding-bottom: 80px;
            .displayList {
                width: 1320px;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .displayItem {
                    width: 355px;
                    height: 275px;
                    padding: 24px;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
                    cursor: pointer;
                    margin-bottom: 25px;
                    transition: 0.3s linear;
                    .center {
                        padding: 0 30px;
                        // flex: 1;
                        font-weight: bold;
                        text-align: center;
                        .date {
                            color: #ce995f;
                            font-size: 15px;
                            margin-bottom: 8px;
                        }
                        .dptitle {
                            text-align: center;
                            font-size: 15px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                    }
                    .right {
                        -webkit-filter: grayscale(
                            100%
                        ); /* Chrome, Safari, Opera */
                        filter: grayscale(100%);
                        img {
                            width: 20px;
                        }
                    }
                }
                .displayItem:hover {
                    transform: translateY(-20px);
                }
                .more {
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    img {
                        width: 70px;
                        transform: rotate(-90deg);
                    }
                }
                .more:hover {
                    transform: translateY(10px);
                }
            }
        }
        .contactUsWrap {
            width: 100%;
            height: 755px;
            position: relative;
            background: url("../../assets/images/contentBG.jpg") no-repeat
                center center / cover;

            .contactUsBox {
                width: 1320px;
                height: 100%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                position: relative;
                z-index: 5;
                .leftView {
                    width: 50%;
                    h3 {
                        color: #ffffff;
                        // font-family: "FrutigerLTStd LT Std";
                        font-size: 36px;
                        letter-spacing: 0;
                        line-height: 43px;
                        padding-top: 95px;
                        padding-bottom: 25px;
                    }
                    p {
                        color: #ffffff;
                        font-family: "FrutigerLTStd LT Std";
                        font-size: 24px;
                        letter-spacing: 0;
                        line-height: 34px;
                    }
                }
                .rightView {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .contactUs {
                        width: 650px;
                        margin: 0 auto;
                        /deep/.el-input__inner,
                        /deep/.el-textarea__inner {
                            height: 60px;
                            background-color: transparent;
                            // border: 2px solid #eee;
                            border-radius: 5px;
                            color: #000;
                            color: #dcdfe6;
                            font-family: "FrutigerLTStd";
                            &::placeholder {
                                color: #dcdfe6;
                                font-size: 14px;
                                font-family: "FrutigerLTStd";
                            }
                        }
                        /deep/.el-textarea__inner {
                            height: 200px;
                            font-family: "FrutigerLTStd";
                        }
                        /deep/.el-input__inner:focus,
                        /deep/.el-textarea__inner:focus {
                            border-color: #eee;
                        }
                        /deep/.el-form-item__content {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            span {
                                font-size: 20px !important;
                            }
                            .el-icon-loading {
                                font-size: 24px !important;
                            }
                        }
                        .submitButton {
                            width: 100%;
                            display: block;
                            border-radius: 30px;
                            background-color: #ce995f;
                            border-color: #ce995f;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .contactUsWrap::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.4);
        }
    }
}
</style>